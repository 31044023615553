import React, { FC, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Badge from "shared/Badge/Badge";
import defaultimage from "images/sagra19.png";
import { Etichette } from "../ExperiencesCardH";
import { HandlerTipologiaProps } from "components/SectionHeroArchivePage/SectionHeroArchivePage";

export interface ExperiencesCardHTypologiesProps {
  item?: any;
  etichette_stati?: Etichette[];
  _setTipologia?: (tipologia: HandlerTipologiaProps) => void;
}

interface ProdottiModel {
  nome: string;
  tariffa_adulto: string;
  tariffa_bambino: string;
}

const ExperiencesCardHTypologies: FC<ExperiencesCardHTypologiesProps> = ({ item, etichette_stati, _setTipologia }) => {
  const [tmp, setServices] = useState<ProdottiModel[]>([]);

  useEffect(() => {
    getProdottiService();
  }, [item]);

  const getProdottiService = () => {
    fetch(`https://booking.jooba.it/getProdottiService?evento_id=${item.evento_id}&tipo_id=${item.tipo_id}`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then(async (response) => {
        setServices(response);
      })
      .catch((error) => console.log(error));
  };

  let etichetta = etichette_stati?.filter((x) => {
    if (x.tipologia_id == item.id) {
      if (item?.stato == "APERTO" && item.stato_id == 5) {
        return x;
      } else if (item?.stato == "ATTESA" && item.stato_id == 6) {
        return x;
      } else if (item?.stato == "RICHIESTA" && item.stato_id == 7) {
        return x;
      } else if (item?.stato == "CHIUSO" && item.stato_id == 8) {
        return x;
      } else return [];
    }
  });

  const tariffaAdulti = tmp && tmp.length > 0 && tmp[0].tariffa_adulto ? tmp[0].tariffa_adulto : "0";
  // const tariffaBambini = tmp && tmp.length > 0 && tmp[0].tariffa_bambino ? tmp[0].tariffa_bambino : "0";

  return (
    <>
      {item?.stato == "APERTO" && item && (
        <button
          onClick={() => {
            if (_setTipologia) {
              _setTipologia({ tipologia_id: item.id, evento_id: item.evento_id });
            } else {
              console.warn("_setTipologia non è definito");
            }
          }}
          className="w-full mt-2 w-full text-green-800 bg-green-100 nc-Badge px-2.5 py-2 rounded-full font-medium transition-colors hover:bg-green-700 hover:text-white duration-300 text-sm"
          style={{ display: "flex", alignItems: "center", textAlign: "left" }}
        >
          <i className="la la-check-circle mr-4 la-lg"></i>
          <div className="flex flex-col">
            <span className="text-xs">
              {(item.etichetta != "" ? item.etichetta : item.tipo) +
                " " +
                (etichetta != null
                  ? etichetta.length > 0
                    ? etichetta[0].etichetta_stato != null && etichetta[0].etichetta_stato != ""
                      ? ": " + etichetta[0].etichetta_stato
                      : ""
                    : ""
                  : "")}
            </span>
          </div>
        </button>
      )}
      {item?.stato == "RICHIESTA" && (
        <div
          className="w-full mt-2 w-full text-yellow-800 bg-yellow-100 nc-Badge px-2.5 py-2 rounded-full font-medium transition-colors hover:bg-yellow-700 hover:text-white duration-300 text-sm"
          style={{ display: "flex", alignItems: "center", textAlign: "left" }}
        >
          <i className="la la-check-circle mr-4 la-lg"></i>
          <div className="flex flex-col">
            <span className="text-xs">
              {(item.etichetta != "" ? item.etichetta : item.tipo) +
                " " +
                (etichetta != null
                  ? etichetta.length > 0
                    ? etichetta[0].etichetta_stato != null && etichetta[0].etichetta_stato != ""
                      ? ": " + etichetta[0].etichetta_stato
                      : ""
                    : ""
                  : "")}
            </span>
          </div>
        </div>
      )}
      {item?.stato == "ATTESA" && (
        <div
          className="w-full mt-2 w-full text-blue-800 bg-blue-100 nc-Badge px-2.5 py-2 rounded-full font-medium transition-colors hover:bg-blue-700 hover:text-white duration-300 text-sm"
          style={{ display: "flex", alignItems: "center", textAlign: "left" }}
        >
          <i className="la la-check-circle mr-4 la-lg"></i>
          <div className="flex flex-col">
            <span className="text-xs">
              {(item.etichetta != "" ? item.etichetta : item.tipo) +
                " " +
                (etichetta != null
                  ? etichetta.length > 0
                    ? etichetta[0].etichetta_stato != null && etichetta[0].etichetta_stato != ""
                      ? ": " + etichetta[0].etichetta_stato
                      : ""
                    : ""
                  : "")}
            </span>
          </div>
        </div>
      )}
      {item?.stato == "CHIUSO" && (
        <div
          className="w-full mt-2 w-full text-red-800 bg-red-100 nc-Badge py-2 rounded-full font-medium text-sm"
          style={{ display: "flex", alignItems: "center", textAlign: "left" }}
        >
          <div className="flex flex-col">
            <span className="text-xs">
              {(item.etichetta != "" ? item.etichetta : item.tipo) +
                " " +
                (etichetta != null
                  ? etichetta.length > 0
                    ? etichetta[0].etichetta_stato != null && etichetta[0].etichetta_stato != ""
                      ? ": " + etichetta[0].etichetta_stato
                      : ""
                    : ""
                  : "")}
            </span>
          </div>
        </div>
      )}
      <div className="pl-2">
        <span className="text-xs font-bold text-neutral-6000">
          {`${
            item.ore_inizio_type != "" && item.ore_inizio_type != null && item.ore_inizio_type != undefined
              ? "Ingresso dalle: " + item.ore_inizio_type
              : ""
          }`}
          {`${item.ore_fine_type != "" && item.ore_fine_type != null && item.ore_fine_type != undefined ? " entro le: " + item.ore_fine_type : ""}`}
        </span>
      </div>
      <div className="p-2">
        <div className="flex justify-between text-neutral-6000 dark:text-neutral-300 text-xs">
          <span className="font-bold">Tariffa adulti</span> <span>€ {parseFloat(item.tariffa_struttura || 0).toFixed(2)}</span>
        </div>
        <div className="flex justify-between text-neutral-6000 dark:text-neutral-300 text-xs">
          <span className="ml-4" style={{ color: "green" }}>
            {tmp[0]?.nome}
          </span>{" "}
          <span style={{ color: "green" }}>€ {parseFloat(tariffaAdulti).toFixed(2)}</span>
        </div>

        {item.evento_token == 1 && (
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300 text-xs">
            <span className="ml-4" style={{ color: "green" }}>{`Spesa minima Food & Beverage`}</span>{" "}
            <span style={{ color: "green" }}>€ {(parseFloat(item?.tariffa_struttura || 0) - parseFloat(tariffaAdulti)).toFixed(2)}</span>
          </div>
        )}
      </div>
    </>
  );
};

export default ExperiencesCardHTypologies;
